import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";

import Translate from "src/ui/components/Translate/Translate";

const NoAppAccessFillPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background-color: var(--color-cream);

  & > nine-bg {
    height: 100%;
  }
`;

const ClosePageAction: FC = () => {
  const handleClosePage = () => {
    window.close();
  };

  useEffect(() => {
    setTimeout(() => {
      handleClosePage();
    }, 1000);
  }, []);

  return (
    <>
      <NoAppAccessFillPage>
        <nine-bg variant="animate-primary" fixed>
          <nine-center>
            <nine-heading
              style={{
                "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
              }}
            >
              <h3 className="as-h4-large">
                <Translate msg="success" />!
              </h3>
              <nine-spacer s="md"></nine-spacer>
              <p className="m0 color-c-80">
                <Translate msg="closeWindow.description" />
              </p>
            </nine-heading>
          </nine-center>
        </nine-bg>
      </NoAppAccessFillPage>
    </>
  );
};

export default ClosePageAction;
